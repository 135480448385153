
<app-loader *ngIf="loading"></app-loader>
<app-get-started 
    *ngIf="currentStep === signUpStep.GetStarted" 
    (onNext)="onNext(signUpStep.GetStarted)"
    (onLoadCompleted)="loading = false"
    [logoUrl]="logoUrl"
    [imageUrl]="getStartedImageUrl"
></app-get-started>
<app-your-details 
    *ngIf="!loading && currentStep === signUpStep.YourDetails"
    (onNext)="onDetailsNext($event)"
    (onBack)="onBack(signUpStep.YourDetails)" 
    (onLoad)="loading = true" 
    (onLoadCompleted)="loading = false"
    [details]="studentDetails"
    [imageUrl]="studentDetailsImageUrl"
></app-your-details>
<app-emergency-contact
    *ngIf="!loading && currentStep === signUpStep.EmergencyContact" 
    (onNext)="onEmergencyContactNext($event)"
    (onBack)="onBack(signUpStep.EmergencyContact)" 
    (onLoad)="loading = true" 
    (onLoadCompleted)="loading = false"
    [emergencyContact]="studentEmergencyContact"
    [imageUrl]="emergencyContactImageUrl"
></app-emergency-contact>
<app-sign-waiver
    [waiverDetails]="waiver"
    *ngIf="!loading && currentStep === signUpStep.SignWaiver" 
    (onNext)="onSignWaiverNext($event)"
    (onBack)="onBack(signUpStep.SignWaiver)" 
    (onLoad)="loading = true" 
    (onLoadCompleted)="loading = false"
></app-sign-waiver>
<app-completed
    *ngIf="!loading && currentStep === signUpStep.Completed" 
></app-completed>