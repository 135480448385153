<div class="m-2">
    <ul class="relative flex flex-row gap-x-2 justify-between">
        <li *ngFor="let step of steps; let last = last" [ngClass]="{'flex-1': !last, 'flex-0': last}" class="flex items-center gap-x-2 shrink basis-0 group">
            <div class="min-w-32 min-h-7 inline-flex flex-col justify-center items-center text-xs align-middle">
                <svg *ngIf="step.completed" width="32px" height="32px" viewBox="0 0 0.96 0.96" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.48 0.84a0.36 0.36 0 1 0 0 -0.72 0.36 0.36 0 0 0 0 0.72m-0.009 -0.214 0.2 -0.24 -0.061 -0.051 -0.172 0.206 -0.089 -0.089 -0.057 0.057 0.12 0.12 0.031 0.031z" fill="#7F56D9"/></svg>
                <svg *ngIf="!step.completed" width="32px" height="32px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path [attr.fill]="step.currentStep ? '#7F56D9' : '#E4E7EC'" d="M8 4c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4z"></path><path [attr.fill]="step.currentStep ? '#7F56D9' : '#E4E7EC'" d="M8 1c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7zM8 0c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8v0z"></path></svg>
                <span [ngClass]="{'text-gray-700': !step.completed && !step.currentStep, 'text-primary-700': step.completed || step.currentStep}" class="block text-sm font-semibold">
                    {{step.title}}
                </span>
            </div>
            <div [ngClass]="{'bg-gray-200': !step.completed, 'bg-primary-600': step.completed }" class="w-full h-[2px] bg-gray-200 flex-1 group-last:hidden mb-4"></div>
        </li>
    </ul>
</div>